/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from "components/Box"
import LanguageSelector from "components/LanguageSelector/LanguageSelector"
import PlanetSwiper from "components/PlanetSwiper"
import IndicatorSwiper from "components/PlanetSwiper/IndicatorSwiper"
import { IndicatorWrapper } from "components/PlanetSwiper/styles"
import Typography from "components/Typography"
import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  @media (min-width: 769px) {
    display: none;
  }
`

const IndexPage = () => {
  const [indicatorSwiper, setIndicatorSwiper] = React.useState(null)
  const [planetSwiper, setPlanetSwiper] = React.useState(null)
  return (
    <Wrapper>
      <Box
        position="absolute"
        top={0}
        zIndex={2}
        display="flex"
        justifyContent="center"
        width="100%"
        my={3}
      >
        <Box>
          <Typography
            variant="menuTitle"
            color="text.secondary"
            fontSize={12}
            letterSpacing={6.5}
            lineHeight={1}
            px={1}
            textAlign="center"
          >
            STANISŁAW
          </Typography>
          <Typography
            variant="menuTitle"
            color="text.secondary"
            lineHeight={1}
            fontSize={56}
            textAlign="center"
            pl={2}
          >
            LEM
          </Typography>
        </Box>
        <LanguageSelector planetSelected={null} />
        <IndicatorSwiper
          onSwiper={setIndicatorSwiper}
          controller={{ control: planetSwiper }}
        />
      </Box>
      <PlanetSwiper
        onSwiper={setPlanetSwiper}
        controller={{ control: indicatorSwiper }}
      />
    </Wrapper>
  )
}

export default IndexPage
